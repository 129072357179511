@import "mixin";


* {
    padding: 0;
    margin: 0;
    font-family: 'Overpass', sans-serif;
    font-size: 14px;
    box-sizing: border-box;
}

html, body {
    min-width: 375px;
    width: 100%;
}

.container {
    max-width: 1252px;
    min-width: 375px;
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;
}

.p5 {
    padding: 5px;
}

.p10 {
    padding: 10px;
}

.m5 {
    margin: 5px;
}

.m10 {
    margin: 10px;
}

.w10 {
    width: 10%;
}

.w20 {
    width: 20%;
}

.w25 {
    width: 25%;
}

.w30 {
    width: 30%;
}

.w33 {
    width: 33.3333%;
}

.w34 {
    width: 34%;
}

.w40 {
    width: 40%;
}

.w50 {
    width: 50%;
}

.w60 {
    width: 60%;
}

.w66 {
    width: 66%;
}

.w75 {
    width: 75%;
}

.w100 {
    width: 100%;
}
@import "icons";
@import "components/header";
@import "components/footer";
@import "components/sections";
@import "components/modal";











