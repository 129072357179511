section.publicationContent {
    padding: 47px 0;
    .container {
        * {
            color: #1F2732;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%; /* 32px */
        }

        .post-quote {
            position: relative;
            padding: 40px 110px 40px 40px;
            background-color: #FAFAFA;
            font-size: 24px;
            font-weight: 300;
            font-stretch: normal;
            font-style: italic;
            line-height: 1.33;
            letter-spacing: normal;
            color: #1F2732;
            margin: 40px 0;
            border-radius: 4px;
        }

        .post-quote img {
            position: relative;
            padding: 20px;
            margin: 0 10px;
        }
        .post-quote:first-child{
            margin-top:0;
        }
        .post-quote table:last-child,
        .post-quote p:last-child,
        .post-quote ul:last-child,
        .post-quote:last-child {
            margin-bottom:0;
        }
        .post-quote-content {
            margin-bottom: 0;
        }
        .post-quote:after {
            position: absolute;
            top: 20px;
            right: 30px;
            display: block;
            text-align: center;
            font-style: normal;
            font-size: 70px;
            margin-left: 10px;
            opacity: 0.4;
            font-family: "antwort" !important;
            content: '\e917';
        }

        em, i {
            font-style: italic;

            * {
                font-style: italic;
            }
        }

        b, strong {
            font-weight: 900;

            * {
                font-style: italic;
            }
        }

        ul, ol {
            margin-left: 1em;
            margin-bottom: 32px;
        }

        li ul, li ol {
            margin-bottom: 0;
        }

        table {
            margin-bottom: 32px;
            th, td {
                padding: 5px;
            }
            tr:nth-child(2n - 1) {
                background: #FAFAFA;
            }
        }

        h1 {
            color: #1F2732;
            font-size: 50px;
            font-style: normal;
            font-weight: 500;
            line-height: 114.6%; /* 57.3px */
            margin-bottom: 50px;
        }

        .image {
            height: 400px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            border-radius: 4px;
            overflow: hidden;
            margin-bottom: 50px;
        }

        p {
            margin-bottom: 32px;
        }

        h2 {
            color: #1F2732;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 160%; /* 51.2px */
            margin-bottom: 20px;
        }
    }
    @media screen and (max-width: 900px) {
        padding: 30px 0 47px;
        .container {
            * {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 24px */
            }

            h1 {
                font-size: 28px;
                font-style: normal;
                font-weight: 500;
                line-height: 114.6%; /* 32.088px */
                margin-bottom: 28px;
            }

            .post-quote {
                padding: 40px 25px 40px 25px;
                font-size: 16px;
                table {
                    display: block;
                    height: auto!important;
                    border: 0;

                    tbody {
                        display: block;
                    }
                    tr {
                        display: block;
                        background: none!important;
                        height: auto!important;
                    }
                    td {
                        display: block;
                        width: calc(100% - 10px)!important;
                        height: auto!important;

                        img {
                            margin: 10px 0;
                            padding: 0;
                            width: 100%!important;
                            height: auto!important;
                        }
                    }
                }

                &:after {
                    font-size: 40px;
                    top: 6px;
                    right: 12px;
                }
            }

            .image {
                height: 220px;
                margin-bottom: 28px;
            }

            p {
                margin-bottom: 24px;
            }

            ul, ol {
                margin-bottom: 24px;
            }

            table {
                margin-bottom: 24px;
            }

            h2 {
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 120%; /* 28.8px */
            }
        }
    }
}
