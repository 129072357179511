section.careerAdvantagesForSpecialists {
    background: #1B222D;
    padding: 97px 0 98px;
    position: relative;
    .content {
        margin-left: calc((100% - 1220px) / 2);
        width: calc(51% - ((100% - 1220px) / 2));

        .image {
            position: absolute;
            overflow: hidden;
            top: 0;
            right: 0;
            bottom: 0;
            width: 49%;
            background-image: url("/images/career/block3.jpg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;

        }

        & > .title {
            position: relative;
            color: #FFF;
            font-size: 40px;
            font-style: normal;
            font-weight: 500;
            line-height: 114.6%; /* 45.84px */
            margin-bottom: 40px;
            padding-left: 20px;
            padding-right: 60px;
            &:before {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 4px;
                background: #FFD43A;
                content: "";
            }
        }
        ol {
            padding-left: 25px;
        }
        .item {
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 33px;
            padding-right: 60px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media screen and (max-width: 1280px) {
        .content {
            margin-left: 16px;
            width: calc(51% - 16px);
        }
    }

    @media screen and (max-width: 900px) {
        padding: 40px 0 0 0;
        .content {
            margin: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            .image {
                order: 2;
                position: static;
                width: 100%;
                height: 375px;
                margin-top: 43px;
            }
            & > .title {
                font-size: 32px;
                font-style: normal;
                font-weight: 400;
                line-height: 114.6%;
                margin-bottom: 32px;
                margin-left: 16px;
                padding-right: 16px;
            }

            ol {
                padding-left: 23px;
            }
            .item {
                padding-right: 16px;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-left: 16px;
                margin-bottom: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
