section.companyDescription {
    padding: 71px 0 30px;
    .container {
        .content {
            padding-left: 20px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 4px;
                background: #FFD43A;
            }

            .text {
                color: #1F2732;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 160%; /* 28.8px */
                * {
                    color: #1F2732;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 160%; /* 28.8px */
                }

                p {
                    margin-bottom: 30px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                table {
                    margin-bottom: 32px;
                    th, td {
                        padding: 5px;
                    }
                    tr:nth-child(2n - 1) {
                        background: #FAFAFA;
                    }
                }

                em, i {
                    font-style: italic;

                    * {
                        font-style: italic;
                    }
                }

                b, strong {
                    font-weight: 900;

                    * {
                        font-style: italic;
                    }
                }

                ul, ol {
                    margin-left: 1em;
                    margin-bottom: 32px;
                }

                li ul, li ol {
                    margin-bottom: 0;
                }


            }
        }
    }

    @media screen and (max-width: 950px) {
        padding: 40px 0 20px;
        .container {
            .content {
                .text {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%; /* 24px */
                    * {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 150%; /* 24px */
                    }
                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 150%; /* 24px */
                        margin-bottom: 25px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                .attributes {
                    display: block;
                    margin-top: 40px;
                    .attribute {
                        padding: 20px;
                        margin-bottom: 12px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .icon {
                            width: 45px;
                            height: 45px;
                            i {
                                font-size: 22px;
                            }
                        }
                        .info {
                            .title {
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }
                            .description {
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 114%; /* 22.8px */
                            }
                        }
                    }
                }
            }
        }
    }
}
