section.serviceDescription {
    padding: 71px 0 72px;
    .container {
        .content {
            padding-left: 20px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 4px;
                background: #FFD43A;
            }

            .text {
                color: #1F2732;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 160%; /* 28.8px */
                * {
                    color: #1F2732;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 160%; /* 28.8px */
                }
                p {
                    margin-bottom: 30px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .attributes {
                margin-top: 50px;
                display: flex;
                .attribute {
                    max-width: 371px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding: 30px;
                    background: #FAFAFA;
                    margin-right: 26px;
                    border-radius: 2px;
                    .icon {
                        width: 60px;
                        height: 60px;
                        position: relative;
                        border-radius: 60px;
                        border: 1px solid #FFD43A;
                        margin-right: 18px;

                        i {
                            color: #FFD43A;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translateX(-50%) translateY(-50%);
                            font-size: 30px;
                        }
                    }
                    .info {
                        .title {
                            color: #828282;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }
                        .description {
                            color: #1F2732;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 114%; /* 27.36px */
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 950px) {
        padding: 40px 0 45px;
        .container {
            .content {
                .text {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%; /* 24px */
                    * {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 150%; /* 24px */
                    }
                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 150%; /* 24px */
                        margin-bottom: 25px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                .attributes {
                    display: block;
                    margin-top: 40px;
                    .attribute {
                        padding: 20px;
                        margin-bottom: 12px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .icon {
                            width: 45px;
                            height: 45px;
                            i {
                                font-size: 22px;
                            }
                        }
                        .info {
                            .title {
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }
                            .description {
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 114%; /* 22.8px */
                            }
                        }
                    }
                }
            }
        }
    }
}
