section.callToAction {
    padding: 99px 0;
    background: #FFD43A;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .title {
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        color: #1B222D;
        max-width: 407px;
    }

    .form {
        display: flex;
    }

    .input {
        border-radius: 4px;
        border: 1px solid #1B222D;
        background: none;
        font-weight: 400;
        font-size: 18px;
        display: block;
        padding: 25px 20px;
        outline: none;
        margin-right: 22px;
        width: 377px;

        &::placeholder {
            color: #4F4F4F;
            opacity: 1;
        }

        &:-ms-input-placeholder {
            color: #4F4F4F;
        }

        &::-ms-input-placeholder {
            color: #4F4F4F;
        }

        &.error {
            border-color: #C01E1E;
        }
    }

    .formBlock {
        position: relative;
        display: flex;
    }
    .errorMessage {
        position: absolute;
        top: calc(100% + 8px);
        color: #C01E1E;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .button {
        cursor: pointer;
        background: #1B222D;
        border-radius: 4px;
        padding: 29px 80px 28px 80px;
        color: #fff;
        font-weight: 500;
        font-size: 22px;
        line-height: 27.85px;
        border: none;

        &:hover {
            background: #1B2738;
        }

        &:active {
            background: #192B46;
        }

        img {
            display: none;
            -webkit-animation:spin 4s linear infinite;
            -moz-animation:spin 4s linear infinite;
            animation:spin 4s linear infinite;

            @-moz-keyframes spin {
                100% { -moz-transform: rotate(360deg); }
            }
            @-webkit-keyframes spin {
                100% { -webkit-transform: rotate(360deg); }
            }
            @keyframes spin {
                100% {
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                }
            }
        }

        &.load {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: #192B46;
                border-radius: 4px;
            }
            img {
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -11px;
                margin-left: -11px;
            }
        }
    }

    @media screen and (max-width: 1220px) {
        padding: 38px 0 46px;
        .container {
            display: block;
        }
        .title {
            display: block;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 20px;
            max-width: none;
        }
        .form {
            display: block;
        }
        .input {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 178.5%; /* 28.56px */
            padding: 15px 20px;
            margin-bottom: 12px;
            width: 100%;
        }
        .formBlock {
            display: block;
        }
        .errorMessage {
            top: calc(100% - 17px);
            right: 4px;
        }
        .button {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            width: 100%;
            padding: 18px;
        }
    }
}
