footer {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-image: url('/images/footer.jpg');
    padding: 89px 0 25px;
    position: relative;
    z-index: 1;

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        //background: rgba(22, 29, 39, 0.7);
        background: linear-gradient(0deg, rgba(22, 29, 39, 0.80) 0%, rgba(22, 29, 39, 0.80) 100%), url('/images/footer.jpg') lightgray 50% / cover no-repeat;
        z-index: -1;
    }

    .container.cols {
        display: flex;
        justify-content: space-between;
    }

    .col {
        .title {
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: 114.6%;
            padding-bottom: 33px;
            border-bottom: 1px solid #666A6F;
            margin-bottom: 33px;
            color: #FFD43A;
        }

        &.about {
            padding-right: 10px;
            max-width: 326px;
            width: 100%;
            .logo {
                max-width: 188px;
                margin-bottom: 25px;
                img {
                    width: 100%;
                }
                object {
                    pointer-events: none;
                }
            }
            .description {
                color: #E0E0E0;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                max-width: 326px;
                width: 100%;
                margin-bottom: 33px;
            }
            .social {
                display: flex;
                .icon {
                    margin-right: 26px;
                }
            }
        }

        &.services {
            padding-right: 10px;
            max-width: 429px;
            width: 100%;
            .items {
                li {
                    list-style: none;
                    margin-bottom: 20px;
                    a {
                        text-decoration: none;
                        color: #E0E0E0;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 200%;
                        transition: 0.145s all ease;

                        &:hover {
                            color: #FFD43A;
                        }
                    }
                }
            }
        }

        &.contacts {
            max-width: 316px;
            width: 100%;
            .title {
                margin-bottom: 28px;
            }
            .feedback {
                margin-bottom: 28px;
                .button {
                    color: #1F2732;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    display: inline-block;
                    border-radius: 4px;
                    background: #FFD43A;
                    padding: 16px 40px;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    transition: 0.145s all ease;

                    &:hover {
                        background: #FFCE20;
                    }
                    &:active {
                        background: #F9C200;
                    }
                }
            }
            .address {
                color: #E0E0E0;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 160%;
                text-transform: capitalize;
                margin-bottom: 28px;
            }

            .phones {
                li {
                    list-style: none;
                    a {
                        text-decoration: none;
                        color: #E0E0E0;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 200%;
                        text-transform: capitalize;

                        .badge {
                            color: #FFD43A;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 200%;
                            text-transform: capitalize;
                        }
                    }
                }
            }
        }
    }


    .copyright {
        margin-top: 60px;
        display: flex;
        padding: 25px 0;
        border-top: 1px solid #666A6F;
        border-bottom: 1px solid #666A6F;
        justify-content: space-between;
        align-items: center;


        .description {
            color: #E0E0E0;
            font-size: 20px;
            font-weight: 400;
        }
        .links {
            text-align: right;

            li {
                list-style: none;
                margin-bottom: 14px;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    color: #FFD43A;
                    text-decoration: none;
                    font-size: 18px;
                }
            }
        }
    }

    @media screen and (max-width: 900px) {
        padding: 44px 0 25px;
        .container.cols {
            display: block;
        }

        .copyright {
            display: block;
            margin-top: 18px;

            .description {
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .links {
                text-align: left;
                margin-top: 37px;
            }
        }

        .col {
            max-width: none!important;
            padding-right: 0!important;
            .title {
                font-size: 30px;
                font-style: normal;
                font-weight: 500;
                line-height: 114.6%; /* 34.38px */
                padding-bottom: 20px;
                margin-bottom: 21px;
            }
            &.about {
                .description {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%; /* 25.6px */
                    margin-bottom: 21px;
                }
                .logo {
                    margin-bottom: 14px;
                }
            }
            &.services {
                margin-top: 34px;
                .items {
                    li {
                        margin-bottom: 12px;
                        a {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 200%; /* 32px */
                        }
                    }
                }
            }
            &.contacts {
                margin-top: 39px;
                .title {
                    margin-bottom: 20px;
                }
                .feedback {
                    margin-bottom: 22px;
                    .button {
                        display: block;
                        width: 100%;
                    }
                }
                .address {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%; /* 25.6px */
                    margin-bottom: 22px;
                }
                .phones {
                    li {
                        a {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 200%; /* 32px */
                            .badge {
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 200%; /* 32px */
                            }
                        }
                    }
                }
            }
        }
    }
}

section.cookieSection {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(22, 29, 39, 0.90);
    padding: 50px 0 50px;
    z-index: 5;

    .container {
        position: relative;
    }

    .close {
        position: absolute;
        top: -40px;
        right: 10px;
        width: 30px;
        height: 30px;
        border: 2px solid #fff;
        border-radius: 15px;
        cursor: pointer;
        font-size: 12px;

        i {
            position: absolute;
            top: 50%;
            left: 50%;
            color: #fff;
            transform: translateX(-50%) translateY(-50%);
            line-height: 0;
        }
    }

    .title {
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 10px;
        color: #fff;
    }
    .description {
        font-size: 16px;
        font-style: normal;
        color: #fff;
        a {
            font-size: 16px;
            color: #fff;
            font-weight: 500;
        }

        .buttons {
            display: flex;
            gap: 30px;
            margin-top: 20px;

            .button {
                font-size: 16px;
                border-bottom: 1px dotted #fff;
            }
        }
    }

    @media screen and (max-width: 900px) {
        .title {
            font-size: 18px;
        }
        .description {
            font-size: 12px;
            a {
                font-size: 12px;
            }
            .buttons {
                .button {
                    font-size: 14px;
                }
            }
        }
    }
}

