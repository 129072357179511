section.services {
    background: #1B222D;
    padding-top: 120px;
    padding-bottom: 117px;

    .head {
        display: flex;
        align-items: center;
        margin-bottom: 60px;
        padding: 3px 0;

        .title {
            font-size: 42px;
            font-weight: 500;
            color: #fff;
        }

        .description {
            position: relative;
            font-size: 24px;
            font-weight: 100;
            line-height: 33px;
            color: #fff;
            padding-left: 53px;

            &:before {
                content: "";
                width: 1px;
                top: 0;
                bottom: 0;
                left: 0;
                position: absolute;
                background: #3A4553;
            }
        }

    }

    .blocks {
        //display: flex;
        //flex-direction: row;
        //flex-wrap: wrap;
        /*display: grid;
        grid-template-columns: 32% 32% 32%;
        grid-column-gap: 25px;
        grid-row-gap: 26px;*/

        overflow: hidden;
        height: 736px;

        &__navigation {
            touch-action: pan-y;
            position: relative;

            .next,
            .preview {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                cursor: pointer;
                padding: 10px;
            }
            .next {
                right: -50px;
            }
            .preview {
                left: -50px;
            }
        }

        .item {
            height: 355px;
            text-decoration: none;
            position: relative;
            background: #1F2732;
            padding: 58px 34px;
            border-radius: 4px;
            transition: ease 0.5ms;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: none;
                border-radius: 4px;
                z-index: 1;
                transition: 0.145s all ease;
            }

            &:hover {
                background: #262F3C;

                &:before {
                    background: #262F3C;
                    top: -6px;
                    bottom: -6px;
                    left: -6px;
                    right: -6px;
                }
            }

            .title {
                color: #fff;
                font-size: 28px;
                font-weight: 400;
                line-height: 35px;
                max-width: 280px;
                padding-bottom: 27px;
                z-index: 1;
            }

            .icon {
                padding-top: 34px;
                position: relative;
                z-index: 1;

                &:before {
                    background: #29313E;
                    height: 1px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    content: "";
                }
            }
        }
    }

    @media screen and (max-width: 1350px) {
        .blocks {
            &__navigation {
                .next,
                .preview {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: 900px) {
        padding-top: 50px;
        padding-bottom: 65px;
        .head {
            display: block;
            padding: 0;
            margin-bottom: 30px;

            .title {
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 12px;
                width: 100%;
            }
            .description {
                font-size: 18px;
                font-style: normal;
                font-weight: 100;
                line-height: 140%; /* 25.2px */
                padding: 0;
                width: 100%;

                &:before {
                    display: none;
                }
            }
        }

        .blocks {
            grid-template-columns: 1fr;
            grid-row-gap: 20px;
            .item {
                padding: 43px 30px 39px;
                .title {
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
                .icon {
                    padding-top: 31px;
                    img {
                        width: 55px;
                    }
                }
            }
        }
    }
}
