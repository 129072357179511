section.servicesList {
    padding: 53px 0;

    .container {
        & > .title {
            color: #1F2732;
            font-size: 42px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 43px;
        }

        .items {
            max-width: 1039px;
            .item {
                text-decoration: none;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 30px;
                color: #1F2732;
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 114.6%; /* 32.088px */
                margin-bottom: 28px;
                background: #FAFAFA;
                transition: 0.145s all ease;

                &:last-child {
                    margin-bottom: 0;
                }

                i {
                    font-size: 30px;
                }

                &:hover {
                    background: #FFD43A;
                }
            }
        }
    }
    @media screen and (max-width: 950px) {
        padding: 47px 0 47px;
        .container {
            & > .title {
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 30px;
            }
            .items {
                .item {
                    padding: 16px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 114.6%; /* 18.336px */
                    margin-bottom: 12px;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    i {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}
