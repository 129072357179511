section.companyValues {
    background: #FAFAFA;
    padding: 84px 0 64px;
    position: relative;
    .content {
        margin-left: calc((100% - 1220px) / 2);
        width: calc(51% - ((100% - 1220px) / 2));

        .image {
            position: absolute;
            overflow: hidden;
            top: 0;
            right: 0;
            bottom: 0;
            width: 49%;
            background-image: url("/images/career/block1.jpg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            /*img {
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
            }*/
        }

        & > .title {
            color: #1F2732;
            font-size: 45px;
            font-style: normal;
            font-weight: 500;
            line-height: 114.6%; /* 51.57px */
            margin-bottom: 40px;
        }
        .item {
            padding-left: 20px;
            position: relative;
            margin-bottom: 20px;
            &:before {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 4px;
                background: #FFD43A;
                content: "";
            }
            .label {
                background: #fff;
                padding: 30px;
                color: #1F2732;
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 114.6%; /* 32.088px */
            }
        }
    }

    @media screen and (max-width: 1280px) {
        .content {
            margin-left: 16px;
            width: calc(51% - 16px);
        }
    }

    @media screen and (max-width: 900px) {
        padding: 45px 0 0 0;
        .content {
            display: flex;
            flex-direction: column;
            margin: 0;
            width: auto;

            .image {
                order: 2;
                position: static;
                height: 423px;
                width: 100%;
                max-width: none;
            }
            & > .title {
                padding-left: 16px;
                padding-right: 16px;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 114.6%; /* 36.672px */
                margin-bottom: 30px;
            }
            .item {
                margin-left: 16px;
                padding-left: 10px;
                margin-bottom: 20px;
                &:before {
                    width: 2px;
                }
                .label {
                    padding: 24px 16px 24px 24px;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 114.6%; /* 20.628px */
                }
            }
        }
    }
}
