section.vacancyPage {
    padding: 68px 0 32px;
    .container {
        .vacancyInfoWrapper {
            display: flex;
            margin-bottom: 40px;
        }
        .vacancyInfoImage {
            width: 100%;
            max-height: 100%;
            background-size: cover;
            background-position: center center;
        }
        .vacancyInfo {
            border-radius: 2px;
            background: #FAFAFA;
            padding: 30px;
            max-width: 622px;
            width: 100%;
            //margin-bottom: 40px;

            .category {
                display: inline-block;
                color: #1F2732;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 25.2px */
                border-radius: 2px;
                background: #FFD43A;
                padding: 8px 20px;
                margin-bottom: 20px;
            }

            .price {
                color: #1F2732;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 4px;
            }

            .location {
                color: #828282;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 20px;
            }

            .date {
                color: #828282;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .text {
            * {
                color: #1F2732;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 36px */
            }

            h2 {
                color: #1F2732;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 14px;
            }

            p {
                margin-bottom: 32px;
            }
            em, i {
                font-style: italic;
            }
            b, strong {
                font-weight: 900;
            }
            ul, ol {
                margin-left: 1em;
                margin-bottom: 32px;
            }
            li ul, li ol {
                margin-bottom: 0;
            }
            table {
                margin-bottom: 32px;
                th, td {
                    padding: 5px;
                }
                tr:nth-child(2n - 1) {
                    background: #FAFAFA;
                }
            }

            .post-quote {
                position: relative;
                padding: 40px 110px 40px 40px;
                background-color: #FAFAFA;
                font-size: 24px;
                font-weight: 300;
                font-stretch: normal;
                font-style: italic;
                line-height: 1.33;
                letter-spacing: normal;
                color: #1F2732;
                margin: 40px 0;
                border-radius: 4px;
            }

            .post-quote img {
                position: relative;
                padding: 20px;
                margin: 0 10px;
            }
            .post-quote:first-child{
                margin-top:0;
            }
            .post-quote table:last-child,
            .post-quote p:last-child,
            .post-quote ul:last-child,
            .post-quote:last-child {
                margin-bottom:0;
            }
            .post-quote-content {
                margin-bottom: 0;
            }
            .post-quote:after {
                position: absolute;
                top: 20px;
                right: 30px;
                display: block;
                text-align: center;
                font-style: normal;
                font-size: 70px;
                margin-left: 10px;
                opacity: 0.4;
                font-family: "antwort" !important;
                content: '\e917';
            }
        }
    }
    @media screen and (max-width: 900px) {
        padding: 39px 0 39px;
        .container {
            .vacancyInfoWrapper {
                display: flex;
                flex-direction: column;
                margin-bottom: 16px;
            }
            .vacancyInfoImage {
                order: 1;
                width: 100%;
                height: 150px;
                background-size: cover;
                background-position: center center;
            }
            .vacancyInfo {
                max-width: unset;
                padding: 20px;
                order: 2;
                .category {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                    padding: 8px 20px;
                    margin-bottom: 16px;
                }
                .price {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 2px;
                }
                .location {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 16px;
                }
                .date {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
            .text {

                * {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%; /* 27px */
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                p {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%; /* 27px */
                    margin-bottom: 32px;
                }
                h2 {
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-bottom: 16px;
                }
                ol,
                ul {
                    margin-bottom: 32px;
                    padding-left: 27px;
                }

                li {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 16px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
