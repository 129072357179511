section.careerDescription {
    padding: 67px 0 53px;
    .container {
        .title {
            color: #1F2732;
            font-size: 50px;
            font-style: normal;
            font-weight: 500;
            line-height: 114.6%; /* 57.3px */
            margin-bottom: 50px;
        }
        .description {
            color: #1F2732;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%; /* 28.8px */
            position: relative;
            padding-left: 20px;

            p {
                color: #1F2732;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 160%; /* 28.8px */
                margin-bottom: 30px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 4px;
                background: #FFD43A;
            }
        }
    }

    @media screen and (max-width: 900px) {
        padding: 42px 0 35px;
        .container {
            .title {
                font-size: 36px;
                font-style: normal;
                font-weight: 500;
                line-height: 114.6%; /* 41.256px */
                margin-bottom: 30px;
            }
            .description {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 24px */
                p {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%; /* 24px */
                    margin-bottom: 25px;
                }
            }
        }
    }
}

