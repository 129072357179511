section.careerForm {
    padding: 77px 0 74px;

    &.vacancy {
        padding: 96px 0 89px;
        background: #FAFAFA;
    }

    .container {
        .title {
            color: #1F2732;
            font-size: 40px;
            font-style: normal;
            font-weight: 500;
            line-height: 114.6%; /* 45.84px */
            padding-left: 20px;
            position: relative;
            max-width: 950px;
            margin-bottom: 16px;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 4px;
                background: #FFD43A;
            }
        }
        .description {
            color: #1F2732;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 36px */
            max-width: 950px;
            margin-bottom: 35px;
        }

        .form {
            max-width: 650px;
            width: 100%;

            .formBlock {
                position: relative;
                margin-bottom: 40px;

                &.file {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                &.mb-30 {
                    margin-bottom: 30px;
                }

                &:first-child {
                    .input {
                        padding-top: 15px;
                    }
                }
            }


            .confirm {
                display: flex;
                align-items: center;
                margin-bottom: 40px;

                input {
                    margin-right: 10px;
                }
            }

            .fileButton {
                cursor: pointer;
                display: inline-block;
                color: #FFF;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding: 18px 27px;
                border-radius: 2px;
                background: #1F2732;

                input {
                    display: none;
                }
            }

            .fileInfo {
                color: #1F2732;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-left: 20px;
            }

            .input {
                width: 100%;
                display: block;
                border: none;
                outline: none;
                border-bottom: 1px solid #3A4553;
                color: #828282;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 178.5%;
                padding-bottom: 11px;
                background: none;

                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus,
                &:-webkit-autofill:active{
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: #828282;
                    transition: background-color 5000s ease-in-out 0s;
                    //box-shadow: inset 0 0 20px 20px #23232329;
                }

                &.error {
                    border-bottom: 1px solid #C01E1E;
                }

                &::placeholder {
                    color: #828282;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 178.5%;
                }
            }

            .errorMessage {
                position: absolute;
                top: calc(100% + 8px);
                color: #C01E1E;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .textarea {
                max-width: 100%;
                min-width: 100%;
                width: 100%;
                max-height: 177px;
                min-height: 177px;
                height: 100%;
                display: block;
                outline: none;
                border: 1px solid #1F2732;
                padding: 17px 24px;
                color: #828282;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 178.5%;
                margin-bottom: 29px;
                border-radius: 2px;
                background: none;

                &.error {
                    border-color: #C01E1E;
                }

                &::placeholder {
                    color: #828282;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 178.5%;
                }
            }
            .submit {
                outline: none;
                background: #FFD43A;
                border-radius: 2px;
                border: none;
                padding: 18px 64px;
                color: #1F2732;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
                cursor: pointer;

                &:hover {
                    background: #FFCE20;
                }
                &:active {
                    background: #F9C200;
                }

                img {
                    display: none;
                    -webkit-animation:spin 4s linear infinite;
                    -moz-animation:spin 4s linear infinite;
                    animation:spin 4s linear infinite;

                    @-moz-keyframes spin {
                        100% { -moz-transform: rotate(360deg); }
                    }
                    @-webkit-keyframes spin {
                        100% { -webkit-transform: rotate(360deg); }
                    }
                    @keyframes spin {
                        100% {
                            -webkit-transform: rotate(360deg);
                            transform: rotate(360deg);
                        }
                    }
                }

                &.load {
                    position: relative;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background: #F9C200;
                    }
                    img {
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        margin-top: -11px;
                        margin-left: -11px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 900px) {
        padding: 40px 0 51px;
        &.vacancy {
            padding: 50px 0 50px;
        }

        .container {
            .title {
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 114.6%; /* 36.672px */
                padding-left: 16px;
                margin-bottom: 16px;
                &:before {
                }
            }
            .description {
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 27px */
                margin-bottom: 50px;
            }
            .form {
                max-width: none;
                width: 100%;
                .formBlock {
                    margin-bottom: 33px;

                    &.file {
                        flex-direction: column;
                    }
                }
                .fileButton {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    display: block;
                    width: 100%;
                    flex-shrink: 0;
                    text-align: center;
                }
                .fileInfo {
                    margin-top: 20px;
                    margin-left: 0;
                }
                .input {
                }
                .textarea {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%; /* 25.6px */
                }
                .submit {
                    width: 100%;
                    display: block;
                    text-align: center;
                }
            }
        }
    }
}
