section.careerEmployment {
    background: #FAFAFA;
    padding: 136px 0 136px;
    position: relative;
    .content {
        margin-left: calc((100% - 1220px) / 2);
        width: calc(51% - ((100% - 1220px) / 2));

        .image {
            position: absolute;
            overflow: hidden;
            top: 0;
            right: 0;
            bottom: 0;
            width: 49%;
            background-image: url("/images/career/block5.jpg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            /*img {
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
            }*/
        }

        & > .title {
            color: #1F2732;
            font-size: 40px;
            font-style: normal;
            font-weight: 500;
            line-height: 114.6%; /* 45.84px */
            margin-bottom: 40px;
        }

        ol {
            padding-left: 25px;
        }

        .item {
            color: #1F2732;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 29px;
            padding-right: 70px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @media screen and (max-width: 1280px) {
        .content {
            margin-left: 16px;
            width: calc(51% - 16px);
        }
    }

    @media screen and (max-width: 900px) {
        padding: 50px 0 0 0;
        .content {
            display: flex;
            flex-direction: column;
            margin: 0;
            width: 100%;
            .image {
                order: 2;
                position: static;
                width: 100%;
                height: 304px;
                margin-top: 40px;
            }
            & > .title {
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 114.6%; /* 36.672px */
                margin-bottom: 32px;
                padding-right: 16px;
                margin-left: 16px;
            }

            ol {
                padding-left: 22px;
            }

            .item {
                margin-left: 16px;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 20px;
                padding-right: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
