section.employees {
    padding: 79px 0;

    & > .container {
        > .title {
            color: #1B222D;
            font-size: 42px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .navigation {
                display: flex;
                align-items: center;
                span.next,
                span.preview {
                    display: block;
                    position: relative;
                    cursor: pointer;
                    width: 64px;
                    height: 64px;
                    border-radius: 32px;
                    transition: 0.145s all ease;
                    i {
                        font-size: 12px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateY(-50%) translateX(-50%);
                    }
                }

                span.next {
                    background: #1F2732;
                    i {
                        color: #FFFFFF;
                    }
                    &:hover {
                        background: #1B2738;
                    }
                    &:active {
                        background: #192B46;
                    }
                }
                span.preview {
                    margin-right: 20px;
                    border: 1px solid #1F2732;
                    i {
                        color: #1F2732;
                    }
                    &:hover {
                        i {
                            color: #FFFFFF;
                        }
                        background: #1B2738;
                    }
                    &:active {
                        i {
                            color: #FFFFFF;
                        }
                        background: #192B46;
                    }
                }
            }

        }
    }

    .slider {
        overflow: hidden;
        touch-action: pan-y;
    }

    .wrapper {
        display: flex;
        align-items: flex-start;
    }

    .card {
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        min-height: 700px;
        border-radius: 6px;
        background: #FAFAFA;
        .image {
            border-radius: 6px 0 0 6px;
            flex-shrink: 0;
            width: 434px;
            position: relative;
            overflow: hidden;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;

            img {
                max-width: 100%;
                max-height: 100%;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
            }
        }
        .info {
            padding: 51px 51px 51px 60px;
            .title {
                color: #1F2732;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 10px;
            }
            .subtitle {
                color: #1F2732;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 34px;
                padding-left: 20px;
                position: relative;

                &:before {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: 4px;
                    background: #FFD43A;
                    content: "";
                }
            }
            .description {
                color: #161D27;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 160%; /* 28.8px */
                * {
                    color: #161D27;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 160%; /* 28.8px */
                }
                p {
                    margin-bottom: 30px;
                }
                em, i {
                    font-style: italic;
                }
                b, strong {
                    font-weight: 900;
                }
                ul, ol {
                    margin-left: 1em;
                    margin-bottom: 30px;
                }
                li ul, li ol {
                    margin-bottom: 0;
                }
                table {
                    margin-bottom: 30px;
                    th, td {
                        padding: 5px;
                    }
                    tr:nth-child(2n - 1) {
                        background: #FAFAFA;
                    }
                }
                .post-quote {
                    position: relative;
                    padding: 40px 110px 40px 40px;
                    background-color: #FAFAFA;
                    font-size: 24px;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: italic;
                    line-height: 1.33;
                    letter-spacing: normal;
                    color: #1F2732;
                    margin: 40px 0;
                    border-radius: 4px;
                }

                .post-quote img {
                    position: relative;
                    padding: 20px;
                    margin: 0 10px;
                }
                .post-quote:first-child{
                    margin-top:0;
                }
                .post-quote table:last-child,
                .post-quote p:last-child,
                .post-quote ul:last-child,
                .post-quote:last-child {
                    margin-bottom:0;
                }
                .post-quote-content {
                    margin-bottom: 0;
                }
                .post-quote:after {
                    position: absolute;
                    top: 20px;
                    right: 30px;
                    display: block;
                    text-align: center;
                    font-style: normal;
                    font-size: 70px;
                    margin-left: 10px;
                    opacity: 0.4;
                    font-family: "antwort" !important;
                    content: '\e917';
                }
            }
        }
    }

    @media screen and (max-width: 950px) {
        padding: 42px 0 39px;
        & > .container {

            &.containerEmployee {
                padding: 16px;
                background: #FAFAFA;
            }
            > .title {
                display: block;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 0;

                .navigation {
                    justify-content: flex-end;
                    margin-top: 20px;
                    margin-bottom: 21px;
                    span.next,
                    span.preview {
                        width: 44px;
                        height: 44px;
                    }
                }

            }
        }
        .card {
            flex-direction: column;
            .image {
                height: 457px;
                width: 100%;
                margin-bottom: 40px;
                border-radius: 0;
                background-position: top center;
            }

            .info {
                padding: 0;
                padding-bottom: 40px;
                .title {
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    margin-bottom: 8px;
                }

                .subtitle {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-bottom: 28px;
                }

                .description {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 150%; /* 24px */
                    p {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 150%; /* 24px */
                        margin-bottom: 25px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
