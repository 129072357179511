@font-face {
    font-family: 'antwort';
    src:  url('/fonts/antwort/antwort.eot?1wl2qb');
    src:  url('/fonts/antwort/antwort.eot?1wl2qb#iefix') format('embedded-opentype'),
    url('/fonts/antwort/antwort.ttf?1wl2qb') format('truetype'),
    url('/fonts/antwort/antwort.woff?1wl2qb') format('woff'),
    url('/fonts/antwort/antwort.svg?1wl2qb#antwort') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'antwort' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
    content: "\e900";
}
.icon-arrow-up:before {
    content: "\e901";
}
.icon-arrow-left:before {
    content: "\e902";
}
.icon-arrow-right:before {
    content: "\e903";
}
.icon-arrow-line-right:before {
    content: "\e904";
}
.icon-close:before {
    content: "\e905";
}
.icon-clock:before {
    content: "\e906";
}
.icon-building:before {
    content: "\e907";
}
.icon-company:before {
    content: "\e908";
}
.icon-vesy:before {
    content: "\e909";
}
.icon-licence:before {
    content: "\e90a";
}
.icon-world:before {
    content: "\e90b";
}
.icon-server:before {
    content: "\e90c";
}
.icon-credit-card:before {
    content: "\e90d";
}
.icon-menu:before {
    content: "\e90e";
}
.icon-mail:before {
    content: "\e90f";
}
.icon-phone:before {
    content: "\e910";
}
.icon-success:before {
    content: "\e911";
}
.icon-telegram:before {
    content: "\e912";
}
.icon-viber:before {
    content: "\e913";
}
.icon-whatsapp:before {
    content: "\e914";
}
.icon-facebook:before {
    content: "\e915";
}
.icon-instagram:before {
    content: "\e916";
}
.icon-quote:before {
    content: "\e917";
}
