.modalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(31, 39, 50, 0.5);
    z-index: 10;
    overflow-y: auto;
    display: none;

    &.open {
        display: block;
    }

    &.successFeedback {
        .modalContent {
            padding: 36px 40px;
            max-width: 465px;
            .image {
                text-align: center;
                margin-bottom: 20px;
            }
            .title {
                color: #1F2732;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-align: center;
                margin-bottom: 8px;
            }
            .description {
                color: #BDBDBD;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 0;
            }
        }
    }

    .modalClose {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    .modalContent {
        max-width: 770px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        z-index: 2;

        border-radius: 4px;
        background: #FAFAFA;
        box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.08), 0px 0px 2px 0px rgba(0, 0, 0, 0.12);
        padding: 65px 60px;

        @media screen and (max-height: 820px) {
            top: 0;
            margin: 20px 0;
            transform: translateX(-50%) translateY(0);
        }

        .closeButton {
            cursor: pointer;
            width: 24px;
            height: 24px;
            border-radius: 29px;
            background: #E0E0E0;
            position: absolute;
            top: 16px;
            right: 16px;

            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                font-size: 10px;
            }
        }

        .title {
            color: #1F2732;
            font-size: 40px;
            font-style: normal;
            font-weight: 500;
            line-height: 114.6%; /* 45.84px */
            margin-bottom: 12px;
        }

        .description {
            color: #828282;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 36px */
            margin-bottom: 25px;
        }

        .form {
            width: 100%;

            .formBlock {
                position: relative;

                &:first-child {
                    .input {
                        padding-top: 15px;
                    }
                }
            }

            .confirm {
                display: flex;
                align-items: center;
                margin-bottom: 40px;

                input {
                    margin-right: 10px;
                }
            }

            .input {
                width: 100%;
                display: block;
                border: none;
                outline: none;
                border-bottom: 1px solid #29313E;
                color: #828282;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 178.5%;
                margin-bottom: 40px;
                padding-bottom: 11px;
                background: none;

                &.error {
                    border-bottom: 1px solid #C01E1E;
                }

                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus,
                &:-webkit-autofill:active{
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: #828282;
                    transition: background-color 5000s ease-in-out 0s;
                    //box-shadow: inset 0 0 20px 20px #23232329;
                }

                &::placeholder {
                    color: #828282;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 178.5%;
                }
            }

            .errorMessage {
                position: absolute;
                top: calc(100% + 8px);
                color: #C01E1E;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .textarea {
                max-width: 100%;
                min-width: 100%;
                width: 100%;
                max-height: 177px;
                min-height: 177px;
                height: 100%;
                display: block;
                outline: none;
                border: 1px solid #29313E;
                padding: 17px 24px;
                color: #828282;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 178.5%;
                margin-bottom: 29px;
                border-radius: 2px;
                background: none;

                &.error {
                    border-color: #C01E1E;
                }

                &::placeholder {
                    color: #828282;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 178.5%;
                }
            }
            .submit {
                outline: none;
                background: #FFD43A;
                border-radius: 2px;
                border: none;
                padding: 18px 64px;
                color: #1F2732;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
                cursor: pointer;

                &:hover {
                    background: #FFCE20;
                }
                &:active {
                    background: #F9C200;
                }

                img {
                    display: none;
                    -webkit-animation:spin 4s linear infinite;
                    -moz-animation:spin 4s linear infinite;
                    animation:spin 4s linear infinite;

                    @-moz-keyframes spin {
                        100% { -moz-transform: rotate(360deg); }
                    }
                    @-webkit-keyframes spin {
                        100% { -webkit-transform: rotate(360deg); }
                    }
                    @keyframes spin {
                        100% {
                            -webkit-transform: rotate(360deg);
                            transform: rotate(360deg);
                        }
                    }
                }

                &.load {
                    position: relative;
                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background: #F9C200;
                        border-radius: 2px;
                    }
                    img {
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        margin-top: -11px;
                        margin-left: -11px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 900px) {
        &.successFeedback {
            .modalContent {
                max-width: none;
            }
        }
        .modalContent {
            max-width: none;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            transform: none;
            border-radius: 0;
            padding: 30px 20px;

            .title {
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .description {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .form {
                .input {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 178.5%; /* 28.56px */
                    margin-bottom: 32px;
                }

                .textarea {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 178.5%; /* 28.56px */
                    padding: 17px 14px;
                }

                .submit {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}
