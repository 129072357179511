section.cases {
    background: #FFFFFF;
    padding: 75px 0;

    .container {
        & > .title {
            font-weight: 500;
            font-size: 42px;
            color: #161D27;
        }
    }

    .sliderCases {
        margin-top: 42px;
        display: flex;
        flex-wrap: nowrap;
        padding-left: calc((100% - 1300px) / 2);
        padding-right: calc((100% - 1220px) / 2);

        &__wrapper {
            touch-action: pan-y;
            overflow: hidden;
            position: relative;

            .gradient {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                background: linear-gradient(270deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.00) 70.65%);
                width: 10%;
                z-index: 2;
            }
        }

    }

    .item {
        padding: 30px;
        //margin-right: 115px;
        position: relative;
        //max-width: 510px;

        width: 100%;
        flex-shrink: 0;
        padding-left: 0;


        &:last-child {
            padding-right: 0;
            &:after {
                display: none;
            }
        }

        &:after {
            content: "";
            position: absolute;
            background: rgba(224, 224, 224, 0.35);
            width: 1px;
            height: 40%;
            top: 30%;
            right: -41px;
        }

        & > .name {
            color: #333;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 12px;
            padding-left: 40px;
        }
        .top {
            background: #FAFAFA;
            border-radius: 4px;
            margin-bottom: 20px;
            padding: 40px;

            .title {
                color: #828282;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px;
                margin-bottom: 12px;
            }
            .description {
                color: #333;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
            }
        }
        .bottom {
            background: #1F2732;
            border-radius: 4px;
            padding: 40px;
            .title {
                color: #FFD43A;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px;
                margin-bottom: 12px;
            }
            .description {
                color: #E0E0E0;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
                margin-bottom: 28px;
            }
            .user {
                display: flex;
                align-items: center;
                .avatar {
                    width: 50px;
                    height: 50px;
                    border-radius: 50px;
                    overflow: hidden;
                    margin-right: 16px;
                    position: relative;

                    img {
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateY(-50%) translateX(-50%);
                    }
                }
                .info {
                    .name {
                        color: #E0E0E0;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                    }
                    .date {
                        color: #E0E0E0;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 900px) {
        padding: 34px 0 30px;
        .container {
            & > .title {
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
        .sliderCases {
            margin-top: -4px;
            padding-left: 16px;
            padding-right: 16px;
        }

        .item {
            //max-width: 285px;
            width: 100%;
            padding-bottom: 0;
            padding-right: 0;
            //margin-right: 44px;

            &:after {
                content: "";
                position: absolute;
                background: rgba(224, 224, 224, 0.35);
                width: 1px;
                height: 40%;
                top: 30%;
                right: -21px;
            }

            & > .name {
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding-left: 16px;
            }
            .top {
                padding: 30px 20px;
                .title {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-bottom: 12px;
                }
                .description {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 137.5% */
                }
            }
            .bottom {
                padding: 30px 16px;
                .title {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-bottom: 12px;
                }
                .description {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px; /* 137.5% */
                }
                .user {
                    .avatar {
                        width: 40px;
                        height: 40px;
                    }

                    .info {
                        .name {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 22.4px */
                        }

                        .date {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 140%; /* 22.4px */
                        }
                    }
                }
            }
        }
    }

}
