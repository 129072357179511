section.news {
    padding: 69px 0 96px;
    & > .container {
        & > .title {
            color: #333;
            font-size: 42px;
            font-style: normal;
            font-weight: 500;
            line-height: 46px;
            margin-bottom: 60px;
        }
    }

    .sliderNews {
        display: flex;
        flex-wrap: nowrap;
        padding-left: calc((100% - 1220px) / 2);
        padding-right: calc((100% - 1220px) / 2);

        &__wrapper {
            touch-action: pan-y;
            overflow: hidden;
        }

        * {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .item {
            //max-width: 390px;
            //margin-right: 28px;
            width: 100%;
            flex-shrink: 0;

            &:last-child {
                margin-right: 0;
            }

            .image {
                display: block;
                border-radius: 4px;
                overflow: hidden;
                text-decoration: none;
                margin-bottom: 16px;
                line-height: 0;
                height: 260px;
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
            }
            .date {
                color: #828282;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                padding-bottom: 10px;
                margin-bottom: 16px;
                border-bottom: 1px solid #F2F2F2;
            }
            .title {
                text-decoration: none;
                display: block;
                color: #333;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 114.6%;
                margin-bottom: 10px;
                @include maxLines(3, 27.5);
            }
            .description {
                color: #828282;
                font-size: 18px;
                font-style: normal;
                font-weight: 300;
                line-height: 140%;
                margin-bottom: 21px;
                @include maxLines(3, 25.2);
            }
            .arrow {
                display: block;
                line-height: 0;
            }
        }
    }
    @media screen and (max-width: 900px) {
        padding: 23px 0 32px;
        & > .container {
            & > .title {
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 30px;
            }
        }
        .sliderNews {
            padding-left: 16px;
            padding-right: 16px;
            .item {
                //max-width: 198px;
                //margin-right: 20px;
                width: 100%;
                .image {
                    height: 140px;
                    margin-bottom: 9px;
                }
                .date {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 16.8px */
                    padding-bottom: 3px;
                    margin-bottom: 9px;
                }
                .title {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 114.6%; /* 18.336px */
                    margin-bottom: 4px;
                }
                .description {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 140%; /* 19.6px */
                    margin-bottom: 11px;
                    @include maxLines(2, 25.2);
                }
                .arrow {
                    img {
                        width: 17px;
                    }
                }
            }
        }
    }

}
