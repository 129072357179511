section.careerBonus {
    background: #1B222D;
    padding: 68px 0 51px;
    position: relative;
    .content {
        margin-left: calc(49% + 70px);
        width: calc(51% - 70px);

        .image {
            position: absolute;
            overflow: hidden;
            top: 0;
            left: 0;
            bottom: 0;
            width: 49%;
            background-image: url("/images/career/block2.jpg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            /*img {
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
            }*/
        }

        & > .title {
            color: #FFF;
            font-size: 45px;
            font-style: normal;
            font-weight: 500;
            line-height: 114.6%; /* 51.57px */
            margin-bottom: 43px;
        }
        & > .subtitle {
            color: #FFF;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 20px;

            &:nth-child(2n) {
                margin-top: 40px;
            }
        }

        .item {
            padding-left: 20px;
            position: relative;
            margin-bottom: 17px;
            &:before {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 4px;
                background: #FFD43A;
                content: "";
            }
            .label {
                background: #1F2732;
                padding: 30px;

                .text {
                    max-width: 584px;
                    color: #FFF;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 114.6%; /* 22.92px */
                }
            }
        }
    }

    @media screen and (max-width: 900px) {
        padding: 40px 0 0 0;
        .content {
            margin: 0;
            width: auto;
            display: flex;
            flex-direction: column;
            .image {
                height: 425px;
                position: static;
                order: 2;
                width: 100%;
            }
            & > .title {
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 114.6%; /* 36.672px */
                margin-bottom: 40px;
                margin-left: 35px;
            }
            & > .subtitle {
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 20px;
                margin-left: 35px;

                &:nth-child(2n) {
                    margin-top: 31px;
                }
            }
            .item {
                margin-left: 35px;
                padding-left: 10px;
                margin-bottom: 12px;
                &:before {
                    width: 2px;
                }
                &:last-child {
                    margin-bottom: 24px;
                }
                .label {
                    padding: 24px 16px 24px 24px;
                    .text {
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 114.6%; /* 20.628px */
                    }
                }
            }
        }
    }
}
