section.careerGallery {
    padding: 49px 0 31px;
    .container {
        .content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .image {
                height: 320px;
                border-radius: 3px;
                overflow: hidden;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                margin-bottom: 30px;
            }
            .im0 {
                width: 57%;
            }
            .im1 {
                width: 40%;
            }
            .im2 {
                width: 32%;
            }
            .im3 {
                width: 66%;
            }
        }
    }
    @media screen and (max-width: 900px) {
        padding: 42px 0 31px;
        .container {
            padding: 0;
            .content {
                flex-direction: column;
                .image {
                    height: 320px;
                    margin-bottom: 8px;
                }
                .im0 {
                    width: 100%;
                }
                .im1 {
                    width: 100%;
                }
                .im2 {
                    width: 100%;
                }
                .im3 {
                    width: 100%;
                }
            }
        }
    }
}
