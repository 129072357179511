section.hero {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding-top: 74px;
    position: relative;


    .slider {
        touch-action: pan-y;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 1;
        .wrapper {
            display: flex;
            position: relative;
            width: 100%;
            height: 100%;
            .slide {
                width: 100%;
                height: 100%;
                flex-shrink: 0;
                position: relative;

                background-position: center center;
                background-size: cover;
                background-repeat: no-repeat;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    width: 100%;
                    min-height: 100%;
                }

                .container {
                    padding-top: 74px;
                    position: relative;
                    z-index: 2;

                    & > .title {
                        max-width: 924px;
                        font-size: 62px;
                        font-weight: 400;
                        margin-top: 86px;
                        color: #F2F2F2;
                        line-height: 74.4px;
                    }
                    & > .description {
                        max-width: 605px;
                        position: relative;
                        margin-top: 12px;
                        font-size: 20px;
                        font-weight: 100;
                        color: #FFFFFF;
                        line-height: 30px;
                        padding: 5px 0 5px 20px;

                        &:before {
                            content: "";
                            width: 4px;
                            background: #FFD43A;
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            bottom: 0;
                        }
                    }
                }
            }
        }
    }


    &.main {
        //background-image: url("/images/hero/homepage.jpg");
        padding-bottom: 120px;

        .container {
            position: relative;
            z-index: 2;

            & > .title {
                max-width: 924px;
                font-size: 62px;
                font-weight: 400;
                margin-top: 86px;
                color: #F2F2F2;
                line-height: 74.4px;
            }
            & > .description {
                max-width: 605px;
                position: relative;
                margin-top: 12px;
                font-size: 20px;
                font-weight: 100;
                color: #FFFFFF;
                line-height: 30px;
                padding: 5px 0 5px 20px;

                &:before {
                    content: "";
                    width: 4px;
                    background: #FFD43A;
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                }
            }
        }

        .callToAction {
            margin-top: 48px;

            a {
                text-decoration: none;
                display: inline-block;
                color: #FFD43A;
                border: 2px solid #FFD43A;
                border-radius: 4px;
                padding: 18px 30px;
                font-size: 16px;
                font-weight: 500;
                cursor: pointer;
                transition: 0.145s all ease;

                &:hover {
                    background: rgba(255, 212, 58, 0.05);
                }
                &:active {
                    background: rgba(255, 212, 58, 0.2);
                }
            }
        }

        .topPadding {
            margin-top: 579px;
        }

        .sliderNavigation {
            display: flex;
            align-items: center;

            .arrow {
                line-height: 0;
                font-size: 14px;
                color: #fff;
                cursor: pointer;
            }

            .number {
                cursor: pointer;
                font-size: 18px;
                font-weight: 300;
                color: #fff;

                &.start {
                    margin-left: 14px;
                    margin-right: 22px;
                }

                &.end {
                    margin-left: 17px;
                    margin-right: 12px;
                }
            }

            .line {
                height: 1px;
                width: 165px;
                position: relative;
                background: rgba(255, 255, 255, 0.6);

                .progress {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: 2px;
                    width: 50px;
                    background: rgba(255, 255, 255, 1);
                }
            }
        }


        .metrics {
            margin-top: 68px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            .item {
                position: relative;
                padding: 30px 22px;

                &:before {
                    content: "";
                    background: rgba(189, 189, 189, 0.4);
                    width: 0.5px;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                }


                .title {
                    font-size: 42px;
                    font-weight: 500;
                    color: #F2F2F2;
                }

                .description {
                    font-size: 18px;
                    font-weight: 400;
                    color: #F2F2F2;
                    margin-top: 4px;
                    max-width: 183px;
                    line-height: 30px;
                }
            }
        }

    }

    &__main {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(67, 67, 67, 0.60) 100%), linear-gradient(0deg, rgba(45, 45, 45, 0.10) 0%, rgba(45, 45, 45, 0.10) 100%), lightgray 0px -80.616px / 100% 166.681% no-repeat;
        padding-top: 410px;
        height: 536px;

        .title,
        h1 {
            color: #F2F2F2;
            font-size: 54px;
            font-style: normal;
            font-weight: 400;
            line-height: 109%;
            position: relative;
            padding-left: 20px;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 4px;
                background: #FFD43A;
            }
        }

        @media screen and (max-width: 950px) {
            height: 250px;
            padding-top: 0;
            position: relative;
            .title,
            h1 {
                display: flex;
                align-items: center;
                position: absolute;
                bottom: 16px;
                left: 16px;
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; /* 33.6px */
                padding-left: 14px;
                right: 30px;

                &:before {
                    width: 2px;
                }
            }
        }
    }

    &__subpage {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(67, 67, 67, 0.60) 100%), linear-gradient(0deg, rgba(45, 45, 45, 0.10) 0%, rgba(45, 45, 45, 0.10) 100%), lightgray 0px -80.616px / 100% 166.681% no-repeat;
        height: 536px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;

        .title {
            padding-bottom: 75px;
            max-width: 931px;
            .subtitle {
                position: relative;
                color: #FFF;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                margin-bottom: 16px;
                padding-left: 20px;

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 4px;
                    background: #FFD43A;
                }
            }
            h1 {
                color: #F2F2F2;
                font-size: 54px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; /* 64.8px */
            }
        }

        @media screen and (max-width: 950px) {
            height: 250px;
            position: relative;
            .title {
                padding: 0;
                position: absolute;
                left: 16px;
                right: 30px;
                bottom: 16px;
                .subtitle {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    padding-left: 14px;
                    margin-bottom: 10px;
                    &:before {
                        width: 2px;
                    }
                }
                h1 {
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%; /* 33.6px */
                }
            }
        }
    }

    &__404 {
        background-image: url("/images/hero/404.jpg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__about {
        background-image: url("/images/hero/about.png");
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__services {
        background-image: url("/images/hero/services.png");
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__service {
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;

        .container {
            z-index: 2;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(67, 67, 67, 0.60) 100%), linear-gradient(0deg, rgba(45, 45, 45, 0.10) 0%, rgba(45, 45, 45, 0.10) 100%), lightgray 0px -80.616px / 100% 166.681% no-repeat;
            z-index: 1;
            opacity: 0.6;
        }
    }

    &__career {
        background-image: url("/images/hero/career.png");
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__vacancy {
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__contacts {
        background-image: url("/images/hero/contacts.png");
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__publications {
        background-image: url("/images/hero/publications.png");
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
    }


    @media screen and (max-width: 950px) {
        &.main {
            padding-bottom: 40px;
            .container {
                & > .title {
                    font-size: 32px!important;
                    font-style: normal!important;
                    font-weight: 400!important;
                    line-height: 120%!important;
                    margin-top: 20px!important;
                }

                & > .description {
                    font-size: 16px!important;
                    font-style: normal!important;
                    font-weight: 100!important;
                    line-height: 150%!important;
                    padding: 2px 0 2px 14px!important;
                    margin-top: 10px!important;
                }
            }
            .topPadding {
                margin-top: 450px;
            }
            .callToAction {
                margin-top: 20px;
                a {
                    text-align: center;
                    display: block;
                    padding: 18px 10px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px; /* 112.5% */
                }
            }
            .sliderNavigation {
                margin-top: 30px;
                justify-content: center;
                .line {
                    width: 223px;
                }
                .number {
                    &.start {
                        margin-left: 7px;
                    }
                    &.end {
                        margin-left: 16px;
                        margin-right: 8px;
                    }
                }
            }
            .metrics {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-row-gap: 38px;
                margin-top: 34px;
                .item {
                    padding: 20px 16px;
                    .title {
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                    .description {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px; /* 125% */
                    }
                }
            }
        }
    }
}
