section.companyTable {
    padding: 30px 0 72px;
    .table {
        .row {
            display: grid;
            grid-template-columns: repeat(10, 1fr);
            border-radius: 3px;

            &:hover {
                background: #f6f6f6;
            }

            &.head {
                background: #FFCE20;

                &:hover {
                    background: #FFCE20;
                }

                .column {
                    padding: 5px 7px;
                    font-weight: 600;
                    color: #1F2732;
                    border-bottom: none;
                }
            }

            .column {
                padding: 7px 7px;
                font-size: 11px;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #BDBDBD;

                .label {
                    display: none;
                }
            }
        }
    }

    .buttons {
        margin: 20px 0;
    }

    .button {
        outline: none;
        background: #FFD43A;
        border-radius: 2px;
        border: none;
        padding: 5px 12px;
        color: #1F2732;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;

        &:hover {
            background: #FFCE20;
        }
        &:active {
            background: #F9C200;
        }
    }

    @media screen and (max-width: 1200px) {
        padding: 20px 0 50px;
        .table {
            .row {
                display: block;
                border-bottom: 1px solid #BDBDBD;
                padding: 10px 0;

                &.head {
                    display: none;
                }

                .column {
                    border-bottom: none;
                    font-size: 12px;

                    &.empty {
                        display: none;
                    }

                    &.companyName {
                        font-size: 14px;
                        font-weight: 600;
                    }

                    .label {
                        display: inline;
                        font-size: 12px;
                        font-weight: 600;
                        margin-right: 7px;
                    }

                }
            }
        }
    }
}
