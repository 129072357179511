section.careerVacancies {
    padding: 75px 0 92px;
    .container {
        .title {
            color: #1F2732;
            font-size: 40px;
            font-style: normal;
            font-weight: 500;
            line-height: 114.6%; /* 45.84px */
            margin-bottom: 16px;
        }
        .subtitle {
            color: #1F2732;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            margin-bottom: 50px;
            max-width: 980px;
        }
        .nav {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            .item {
                color: #1F2732;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 25.2px */
                padding: 8px 20px;
                margin-right: 12px;
                border-radius: 2px;
                cursor: pointer;

                &.active {
                    background: #FFD43A;
                }
            }
        }

        .slider {
            position: relative;
            .prev,
            .next {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);

                i {
                    font-size: 24px;
                    color: rgba(130, 130, 130, 0.7);
                }
            }
            .prev {
               left: -50px;
            }
            .next {
                right: -50px;
            }
            .wrapperSlider {
                overflow: hidden;
            }
            .wrapper {
                display: flex;
                .item {
                    flex-shrink: 0;
                    max-width: 388px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    border-radius: 2px;
                    background: #FAFAFA;
                    min-height: 338px;
                    text-decoration: none;
                    padding: 30px;
                    margin-right: 28px;

                    &:last-child {
                        margin-right: 0;
                    }

                    &:hover {
                        background: #F2F2F2;
                    }

                    .title {
                        color: #1F2732;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        margin-bottom: 2px;
                    }
                    .price {
                        color: #1F2732;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin-bottom: 6px;
                    }
                    .location {
                        color: #828282;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin-bottom: 28px;
                    }
                    .description {
                        color: #1F2732;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                    .date {
                        color: #828282;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        margin-top: auto;
                        padding-top: 16px;
                    }
                }
            }
        }
    }


    @media screen and (max-width: 1340px) {
        .container {
            .slider {
                .prev,
                .next {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: 900px) {
        padding: 24px 0 39px;
        .container {
            .title {
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 114.6%; /* 36.672px */
                margin-bottom: 12px;
            }

            .subtitle {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 114.6%; /* 18.336px */
                margin-bottom: 34px;
            }

            .nav {
                .item {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                    margin-right: 8px;
                    padding: 8px 18px;
                }
            }

            .slider {
                .prev,
                .next {
                    display: none;
                }

                .wrapper {
                    flex-direction: column;
                    .item {
                        min-height: 326px;
                        padding: 24px;
                        margin-bottom: 16px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                        .title {
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }

                        .price {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }

                        .location {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }

                        .description {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }

                        .date {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }
}
