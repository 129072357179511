section.servicesDescription {
    padding: 54px 0;

    .content {
        max-width: 1170px;
        color: #1F2732;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%;
        position: relative;
        padding-left: 20px;

        p {
            color: #1F2732;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 160%;
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 4px;
            background: #FFD43A;
        }
    }

    @media screen and (max-width: 950px) {
        padding: 40px 0 42px;
        .content {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 24px */
            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 24px */
                margin-bottom: 24px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
