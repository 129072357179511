section.careerAdvantages {
    padding: 72px 0 60px;
    overflow: hidden;

    .container {
        .title {
            color: #1F2732;
            font-size: 45px;
            font-style: normal;
            font-weight: 500;
            line-height: 114.6%; /* 51.57px */
            margin-bottom: 60px;
        }

        .content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .item {
            flex-shrink: 0;
            color: #1F2732;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 36px */
            padding: 30px 30px 30px 34px;
            background: #FAFAFA;
            border-radius: 0px 2px 2px 0px;
            position: relative;
            margin-bottom: 28px;
            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 4px;
                background: #FFD43A;
            }

            &.item0 { width: 57% }
            &.item1 {
                width: 41%;
                position: relative;

                img.emoji {
                    position: absolute;
                    right: -22%;
                    top: -25%;
                }
            }
            &.item2 {
                width: 23%;
                position: relative;

                img.emoji {
                    position: absolute;
                    right: -20%;
                    top: 10%;
                }
            }
            &.item3 { width: 36% }
            &.item4 { width: 36% }
            &.item5 {
                width: 100%;
                position: relative;

                img.emoji {
                    position: absolute;
                    left: 28%;
                    top: 16%;
                }
            }
        }
    }
    @media screen and (max-width: 900px) {
        padding: 43px 0 3px;

        .container {
            .title {
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 114.6%; /* 36.672px */
                margin-bottom: 30px;
            }
            .content {
                flex-direction: column;
                position: relative;
            }
            .item {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 22.4px */
                padding: 20px 20px 20px 22px;
                margin-bottom: 28px;

                &:before {
                    width: 2px;
                }
                &.item0 {
                    width: 100%;
                }
                &.item1 {
                    width: 100%;
                    img.emoji {
                        width: 130px;
                        right: -7%;
                        top: -238%;
                    }
                }
                &.item2 {
                    width: 100%;
                    img.emoji {
                        width: 180px;
                        right: -7%;
                        top: -50%;
                    }
                }
                &.item3 {
                    width: 100%;
                }
                &.item4 {
                    width: 100%;
                }
                &.item5 {
                    width: 100%;
                    img.emoji {
                        width: 180px;
                        left: 18%;
                        top: 56%;
                    }
                }
            }
        }
    }
}
