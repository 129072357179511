section.faq {
    background: #FFFFFF;
    padding: 95px 0 96px;

    & > .container {
        & > .title {
            color: #333;
            font-size: 42px;
            font-style: normal;
            font-weight: 500;
            line-height: 54px;
            margin-bottom: 28px;
        }
    }

    .questions {
        .item {
            padding: 32px 0;
            border-bottom: 1px solid #F2F2F2;
            .title {
                color: #1F2732;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 114.6%;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;

                img {
                    padding-left: 20px;
                }
            }
            .answer {
                display: none;
                padding-top: 16px;
                color: #909090;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                white-space: pre-wrap;
            }

            .rotate {
                transform: rotateX(180deg);
            }
        }

    }
    @media screen and (max-width: 900px) {
        padding: 38px 0;
        & > .container {
            & > .title {
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 0;
            }
        }
        .questions {
            .item {
                padding: 32px 0 26px;
                .title {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 114.6%; /* 22.92px */
                }
                .answer {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                    white-space: pre-wrap;
                }
            }
        }
    }
}
