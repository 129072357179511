section.servicesFaq {
    position: relative;
    background: #FAFAFA;

    .container {
        .content {
            max-width: 619px;
            padding-right: 50px;
            padding-top: 110px;
            padding-bottom: 135px;

            & > .title {
                color: #333;
                font-size: 42px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 61px;
            }
        }
    }

    .questions {
        .item {
            padding-bottom: 40px;

            &:last-child {
                padding-bottom: 0;
            }

            &:after {
                margin-top: 40px;
                height: 1px;
                background: #F2F2F2;
                content: "";
                display: block;
            }

            .title {
                color: #1F2732;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 114.6%; /* 27.504px */
                margin-bottom: 16px;
            }
            .answer {
                color: #828282;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 25.2px */
                white-space: pre-wrap;
            }
        }
    }


    .image {
        max-width: 43%;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        background-image: url("/images/services/faq.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }


    @media screen and (max-width: 950px) {
        display: flex;
        flex-direction: column;
        .container {
            order: 1;
            .content {
                padding-top: 38px;
                padding-bottom: 58px;
                padding-right: 0;
                max-width: none;
                & > .title {
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-bottom: 30px;
                }
            }
        }
        .questions {
            .item {
                padding-bottom: 28px;
                &:last-child {
                    padding-bottom: 0;
                }
                &:after {
                    margin-top: 28px;
                }
                .title {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 114.6%; /* 22.92px */
                }
                .answer {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                    white-space: pre-wrap;
                }
            }
        }
        .image {
            max-width: none;
            position: static;
            order: 2;
            height: 581px;
        }
    }
}
