section.advantages {
    padding: 44px 0 72px;

    & > .container {
        > .title {
            color: #1F2732;
            font-size: 42px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 43px;
        }
    }


    .items {
        display: grid;

        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: auto auto auto;

        //grid-template-columns: 1fr 1fr 1fr;
        //grid-template-rows: auto;
        grid-column-gap: 28px;
        grid-row-gap: 34px;

        .item {
            grid-column: span 2;
            border-radius: 4px;
            background: #FAFAFA;
            padding: 32px;
            padding-top: 35px;

            &.c2 {
                grid-column: span 3;
            }

            .number {
                color: #FFD43A;
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 16px;
            }

            .title {
                color: #1F2732;
                font-size: 26px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 8px;
            }

            .description {
                color: #1F2732;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px; /* 166.667% */
            }
        }
    }
    @media screen and (max-width: 950px) {
        padding: 12px 0 36px;
        & > .container {
            > .title {
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 21px;
            }
        }
        .items {
            display: block;
            .item {
                padding: 20px;
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }

                &.c2 {
                }
                .number {
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
                .title {
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
                .description {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 25px; /* 156.25% */
                }
            }
        }
    }
}
