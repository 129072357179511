section.capLogo {
    height: 429px;
    background-image: url("/images/about/description.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;


    @media screen and (max-width: 950px) {
        height: 253px;
        background-image: url("/images/about/description-mobile.png");
    }
}
