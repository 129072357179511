section.partners {
    background: #FAFAFA;
    padding: 75px 0;

    .title {
        color: #161D27;
        font-size: 45px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 50px;
    }

    .slider {
        display: flex;
        //overflow: hidden;
        align-items: center;

        &__content {
            overflow: hidden;
            height: 66px;
        }
        &__wrapper {
            touch-action: pan-y;
            position: relative;

            .next,
            .preview {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
            .next {
                right: -25px;
            }
            .preview {
                left: -25px;
            }
        }

        .item {
            height: 66px;
            //margin-left: 34px;

            &:first-child {
                margin-left: 0;
            }
            img {
                height: 100%;
            }
        }
    }

    @media screen and (max-width: 1290px) {
        .slider {
            &__wrapper {
                .next,
                .preview {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: 900px) {
        padding: 37px 0 47px;
        .title {
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 114.6%; /* 36.672px */
            margin-bottom: 20px;
        }
        .slider {
            &__content {
                height: 150px;
            }
            &__wrapper {
                .next,
                .preview {
                    display: none;
                }
            }
            .item {
                height: 50px;
                //margin-left: 37px;
            }
        }
    }
}
