section.feedbackForm {
    background: linear-gradient(0deg, rgba(31, 39, 50, 0.50) 0%, rgba(31, 39, 50, 0.50) 100%), url("/images/feedbackForm.jpg"), lightgray 0px -530.5px / 100% 204.934% no-repeat;
    background-position: center center;
    padding: 135px 0 111px;


    .container {
        & > .title {
            color: #FFD43A;
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: 114.6%;
            margin-bottom: 17px;
        }

        & > .description {
            color: #FFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: 140%;

            margin-bottom: 65px;
        }
    }


    .content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background: #1F2732;
        border-radius: 4px;
        padding: 80px;
        padding-bottom: 60px;
    }

    .form {
        max-width: 500px;
        padding-right: 20px;
        width: 100%;

        .formBlock {
            position: relative;

            &:first-child {
                .input {
                    padding-top: 15px;
                }
            }
        }

        .confirm {
            display: flex;
            align-items: center;
            margin-bottom: 40px;
            color: #D7D7D7;

            input {
                margin-right: 10px;
            }
        }

        .input {
            width: 100%;
            display: block;
            border: none;
            outline: none;
            border-bottom: 1px solid #3A4553;
            color: #D7D7D7;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 178.5%;
            margin-bottom: 40px;
            padding-bottom: 11px;
            background: none;

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active{
                -webkit-background-clip: text;
                -webkit-text-fill-color: #D7D7D7;
                transition: background-color 5000s ease-in-out 0s;
                //box-shadow: inset 0 0 20px 20px #23232329;
            }

            &.error {
                border-bottom: 1px solid #C01E1E;
            }

            &::placeholder {
                color: #D7D7D7;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 178.5%;
            }
        }

        .errorMessage {
            position: absolute;
            top: calc(100% + 8px);
            color: #C01E1E;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .textarea {
            max-width: 100%;
            min-width: 100%;
            width: 100%;
            max-height: 137px;
            min-height: 137px;
            height: 100%;
            display: block;
            outline: none;
            border: 1px solid #FFD43A;
            padding: 17px 24px;
            color: #D7D7D7;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 178.5%;
            margin-bottom: 29px;
            border-radius: 2px;
            background: none;

            &.error {
                border-color: #C01E1E;
            }

            &::placeholder {
                color: #D7D7D7;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 178.5%;
            }
        }
        .submit {
            outline: none;
            background: #FFD43A;
            border-radius: 2px;
            border: none;
            padding: 18px 64px;
            color: #1F2732;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            cursor: pointer;

            img {
                display: none;
                -webkit-animation:spin 4s linear infinite;
                -moz-animation:spin 4s linear infinite;
                animation:spin 4s linear infinite;

                @-moz-keyframes spin {
                    100% { -moz-transform: rotate(360deg); }
                }
                @-webkit-keyframes spin {
                    100% { -webkit-transform: rotate(360deg); }
                }
                @keyframes spin {
                    100% {
                        -webkit-transform: rotate(360deg);
                        transform: rotate(360deg);
                    }
                }
            }

            &.load {
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background: #F9C200;
                    border-radius: 2px;
                }
                img {
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-top: -11px;
                    margin-left: -11px;
                }
            }

            &:hover {
                background: #FFCE20;
            }
            &:active {
                background: #F9C200;
            }
        }
    }

    .contacts {
        width: 100%;
        max-width: 373px;
        padding-top: 8px;
        .item {
            display: flex;
            align-items: center;
            padding-top: 14px;
            padding-bottom: 14px;

            &:first-child {
                padding-top: 0;
            }

            &.small {
                padding-top: 10px;
                padding-bottom: 11px;
            }

            .icon {
                position: relative;
                width: 45px;
                height: 45px;
                border-radius: 45px;
                border: 1px solid #FFD43A;
                margin-right: 16px;

                i {
                    color: #FFD43A;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateY(-50%) translateX(-50%);
                }
            }
            .description {
                display: flex;
                flex-direction: column;

                .head {
                    color: #FFD43A;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;
                }
                .link {
                    color: #FFF;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;

                    a {
                        text-decoration: none;
                        color: #FFF;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                    }
                }
            }
        }
    }

    &.service {
        background: #FAFAFA;
        padding: 95px 0 73px;

        .container {
            > .title {
                color: #1F2732;
                font-size: 40px;
                font-style: normal;
                font-weight: 500;
                line-height: 114.6%;
                position: relative;
                padding-left: 20px;
                margin-bottom: 16px;

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 4px;
                    background: #FFD43A;
                }
            }
            > .description {
                color: #1F2732;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                max-width: 750px;
                margin-bottom: 35px;
            }
            .content {
                background: none;
                padding: 0;

                .form {
                    max-width: 650px;

                    .input {
                        color: #828282;
                        &::placeholder {
                            color: #828282;
                        }

                        &:-webkit-autofill,
                        &:-webkit-autofill:hover,
                        &:-webkit-autofill:focus,
                        &:-webkit-autofill:active{
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: #828282;
                            transition: background-color 5000s ease-in-out 0s;
                            //box-shadow: inset 0 0 20px 20px #23232329;
                        }
                    }

                    .confirm {
                        color: #828282;
                    }

                    .textarea {
                        max-height: 176px;
                        min-height: 176px;
                        color: #828282;
                        border-color: #1F2732;
                        &::placeholder {
                            color: #828282;
                        }
                    }
                }
            }

            .contacts {
                padding-top: 15px;
                max-width: 400px;

                .item {
                    padding-bottom: 19px;
                    &.small {
                        padding-top: 12px;
                        padding-bottom: 18px;
                    }
                }

                .head {
                    color: #1F2732;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-transform: capitalize;
                }
                .link {
                    color: #1F2732;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    a {
                        color: #1F2732;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        text-decoration: none;
                    }
                }

                .icon {
                    border-color: #1F2732;
                    i {
                        color: #1F2732;
                    }
                }
            }
        }
        @media screen and (max-width: 900px) {
            padding: 40px 0 40px;
            .container {
                > .title {
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 114.6%; /* 36.672px */
                    margin-bottom: 16px;
                    padding-left: 16px;
                    &:before {

                    }
                }
                > .description {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%; /* 27px */
                }
                .content {
                    .form {
                        padding: 0;
                        padding-top: 20px;
                        background: none;

                        .input {
                            margin-bottom: 37px;
                        }
                        .textarea {
                            padding: 17px 17px 17px 24px;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 160%; /* 25.6px */
                        }
                    }
                    .contacts {
                        background: none;
                        padding: 0;
                        padding-top: 39px;

                        .item {
                            padding-bottom: 10px;
                            &.small {
                                padding-top: 8px;
                                padding-bottom: 7px;
                            }
                        }
                    }
                }
            }
        }
    }

    &.contacts {
        background: #FAFAFA;
        padding: 89px 0 66px;

        .container {
            > .title {
                color: #1F2732;
                font-size: 40px;
                font-style: normal;
                font-weight: 500;
                line-height: 114.6%;
                position: relative;
                padding-left: 20px;
                margin-bottom: 16px;

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 4px;
                    background: #FFD43A;
                }
            }
            > .description {
                color: #1F2732;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                max-width: 750px;
                margin-bottom: 35px;
            }
            .content {
                background: none;
                padding: 0;

                .form {
                    max-width: 650px;

                    .input {
                        border-color: #29313E;
                        color: #828282;
                        &::placeholder {
                            color: #828282;
                        }

                        &:-webkit-autofill,
                        &:-webkit-autofill:hover,
                        &:-webkit-autofill:focus,
                        &:-webkit-autofill:active{
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: #828282;
                            transition: background-color 5000s ease-in-out 0s;
                            //box-shadow: inset 0 0 20px 20px #23232329;
                        }
                    }

                    .confirm {
                        color: #828282;
                    }

                    .textarea {
                        max-height: 176px;
                        min-height: 176px;
                        color: #828282;
                        border-color: #1F2732;
                        &::placeholder {
                            color: #828282;
                        }
                    }
                }
            }

            .contacts {
                padding-top: 15px;
                max-width: 400px;

                .item {
                    padding-bottom: 19px;
                    &.small {
                        padding-top: 12px;
                        padding-bottom: 18px;
                    }
                }

                .head {
                    color: #1F2732;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-transform: capitalize;
                }
                .link {
                    color: #1F2732;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    a {
                        color: #1F2732;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        text-decoration: none;
                    }
                }

                .icon {
                    border-color: #1F2732;
                    i {
                        color: #1F2732;
                    }
                }
            }
        }
        @media screen and (max-width: 900px) {
            padding: 40px 0 40px;
            .container {
                > .title {
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 114.6%; /* 36.672px */
                    margin-bottom: 16px;
                    padding-left: 16px;
                    &:before {

                    }
                }
                > .description {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%; /* 27px */
                }
                .content {
                    .form {
                        padding: 0;
                        background: none;
                        padding-top: 20px;
                        .input {
                            margin-bottom: 36px;
                        }
                        .textarea {
                            padding: 17px 17px 17px 24px;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 160%; /* 25.6px */
                        }
                    }
                    .contacts {
                        background: none;
                        padding: 0;
                        padding-top: 39px;

                        .item {
                            padding-bottom: 10px;
                            &.small {
                                padding-top: 8px;
                                padding-bottom: 7px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 900px) {
        padding: 55px 0 38px;
        .container {
            & > .title {
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 114.6%; /* 27.504px */
                margin-bottom: 17px;
            }
            & > .description {
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                margin-bottom: 35px;
            }
        }
        .content {
            display: block;
            background: none;
            padding: 0;

            .form {
                max-width: none;
                background: #1F2732;
                padding: 24px;
                margin-bottom: 8px;
                padding-top: 37px;

                .input {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 178.5%; /* 28.56px */
                    margin-bottom: 33px;
                }
                .textarea {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 160%; /* 25.6px */
                    margin-bottom: 33px;
                    padding: 17px 14px;
                }
                .submit {
                    display: block;
                    width: 100%;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;
                }
            }

            .contacts {
                max-width: none;
                background: #1F2732;
                padding: 32px 24px;

                .item {
                    padding-top: 8px;
                    padding-bottom: 10px;

                    &.small {
                        padding-top: 9px;
                        padding-bottom: 7px;
                    }

                    &:first-child {
                        padding-top: 0;
                    }

                    .icon {
                        width: 40px;
                        height: 40px;
                    }
                    .description {
                        .head {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            text-transform: capitalize;
                        }
                        .link {
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 300;
                            line-height: normal;
                            a {
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: normal;
                            }
                        }
                    }

                }

            }
        }
    }
}
