header {

    .head {
        min-width: 375px;
        background: rgba(31, 39, 50, 0.5);
        backdrop-filter: blur(20px);
        position: absolute;
        left: 0;
        right: 0;
        z-index: 10;

        & > .container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .navigation {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .backgroundMobile {
                display: none;
            }

            > .item {
                & > a {
                    padding: 27px 12px 27px 11px;
                    text-decoration: none;
                    color: #fff;
                    font-size: 16px;
                    font-weight: 400;
                    display: block;
                }

                &:first-child {
                    a {
                        padding-left: 0;
                    }
                }

                &:hover {
                    & > a {
                        position: relative;

                        &:after {
                            content: "";
                            height: 2px;
                            width: 100%;
                            background: #fff;
                            display: block;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                        }
                    }

                    .subMenu {
                        display: block;

                        & > .container {
                            & > .w50 > .item:hover {
                                & > .link {
                                    a {
                                        color: #FFD43A;
                                    }
                                    i {
                                        color: #FFD43A;
                                        margin-right: -15px;
                                    }
                                }
                            }
                        }
                    }
                }



                .subMenu {
                    display: none;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    right: 0;
                    background: rgba(31, 39, 50, 0.95);
                    backdrop-filter: blur(10px);
                    padding-top: 92px;
                    padding-bottom: 130px;
                    z-index: 1;

                    .mobilePanel {
                        display: none;
                    }

                    /*.back {
                        display: none;
                    }*/

                    & > .container {
                        position: relative;

                        & > .w50 > .item {
                            & > .link {
                                cursor: pointer;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                & > a {
                                    flex: 1;
                                    padding: 20px 16px 21px;
                                    text-decoration: none;
                                    color: #fff;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    font-size: 24px;
                                    font-weight: 400;
                                    transition: 0.145s all ease;
                                }
                                i {
                                    color: #fff;
                                    transition: 0.145s all ease;
                                    font-size: 30px;
                                }
                                &:hover {
                                    a {
                                        color: #FFD43A;
                                    }
                                    i {
                                        color: #FFD43A;
                                        margin-right: -15px;
                                    }
                                }
                            }

                            &:hover {
                                .subChildMenu {
                                    display: block;
                                }
                            }

                            .subChildMenu {
                                display: none;
                                position: absolute;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                width: 50%;
                                padding-left: 90px;
                                padding-top: 25px;

                                .item {
                                    a {
                                        margin-bottom: 18px;
                                        text-decoration: none;
                                        color: #fff;
                                        display: flex;
                                        justify-content: space-between;
                                        font-size: 18px;
                                        font-weight: 300;
                                        transition: 0.145s all ease;

                                        &:hover {
                                            color: #FFD43A;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .contacts {
                display: none;
            }
        }

        .logo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%) translate3d(0,0,0);
            max-width: 188px;

            a {
                display: block;
                line-height: 0;
            }

            object {
                pointer-events: none;
            }

            img {
                width: 100%;
                transform: unset !important;
                //transform: translate3d(0,0,0);
                //-webkit-transform: translate3d(0,0,0);
            }

        }

        .rightBlock {
            display: flex;
            flex-direction: row;
            align-items: center;

            .contacts {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 16px;
                font-weight: 300;
                color: #fff;
                margin-right: 20px;

                & > a,
                & > div {
                    color: #fff;
                    text-decoration: none;
                    font-size: 16px;

                    &:first-child {
                        padding-right: 15px;
                        display: flex;
                        align-items: center;

                        &:after {
                            content: "";
                            width: 2px;
                            height: 18px;
                            background: rgba(255, 255, 255, 0.7);
                            display: inline-block;
                            margin-left: 15px;
                        }
                    }
                }
            }

            .menuButton {
                display: none;
            }

            .languages {
                position: relative;
                height: 100%;

                .label {
                    padding: 9px 13px 9px 10px;
                    border: 0.5px solid #fff;
                    border-radius: 2px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    span {
                        color: #fff;
                        font-size: 16px;
                        font-weight: 300;
                        padding-right: 9px;
                        display: block;
                        height: 16px;
                        text-transform: uppercase;
                    }

                    img {
                        transition: 0.145s all ease;
                    }
                }

                /*&:hover {
                    .subLanguages {
                        display: block;
                    }
                    .label {
                        img {
                            transform: rotateZ(-180deg);
                        }
                    }
                }*/

                &.open {
                    .subLanguages {
                        display: block;
                    }
                    .label {
                        img {
                            transform: rotateZ(-180deg);
                        }
                    }
                }

                .subLanguages {
                    transition: 0.145s all ease;
                    display: none;
                    padding-top: 19px;
                    position: absolute;
                    top: 100%;
                    right: 0;

                    .content {
                        width: 247px;
                        padding: 10px 0;
                        background: rgba(31, 39, 50, 0.5);
                        display: flex;
                        flex-direction: column;

                        .item {
                            cursor: pointer;
                            text-decoration: none;
                            color: #FFF;
                            font-size: 16px;
                            font-weight: 300;
                            padding: 14px 24px;

                            &:hover {
                                background: rgba(31, 39, 50, 0.7);
                            }

                            &.active {
                                color: #FFD43A;
                            }
                        }
                    }

                }
            }
        }
    }

    @media screen and (max-width: 1100px) {
        .head {
            & > .container {
                padding: 9px 16px 9px 14px;
            }
            .navigation {
                display: none;
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                background: rgba(31, 39, 50, 0.95);
                backdrop-filter: blur(10px);
                //padding: 13px 16px;

                .backgroundMobile {
                    display: block;
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: #000;
                }

                &.hide {
                    height: 0!important;
                    overflow-y: visible!important;
                }

                &.open {
                    height: calc(100vh - 53px);
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    overflow-y: auto;
                }

                .contacts {
                    //margin-top: 200px;
                    margin-top: auto;
                    padding: 30px 16px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    & > div {
                        color: #fff;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 18px; /* 112.5% */

                        &:first-child {
                            padding-right: 12px;
                            display: flex;
                            align-items: center;

                            &:after {
                                content: "";
                                width: 2px;
                                height: 18px;
                                background: rgba(255, 255, 255, 0.7);
                                display: inline-block;
                                margin-left: 12px;
                            }
                        }
                    }
                }

                > .item {
                    & > a {
                        position: relative;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 114.6%; /* 22.92px */
                        display: block;
                        padding: 20px 16px;

                        &:first-child {
                            padding-left: 16px;
                        }

                        &.close {
                            display: none;
                        }

                        &:after {
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            left: 0;
                            content: "";
                            height: 1px;
                            display: block;
                            background: rgba(231, 231, 231, 0.20);
                        }
                    }


                    &:hover {
                        & > a {
                            color: #FFD43A;
                            &:after {
                                position: absolute;
                                bottom: 0;
                                right: 0;
                                left: 0;
                                content: "";
                                height: 1px;
                                display: block;
                                background: rgba(231, 231, 231, 0.20);
                            }
                        }
                        .subMenu {
                            display: none;

                            & > .container {
                                & > .w50 > .item:hover {
                                    & > .link {
                                        & > a {
                                            color: #FFD43A;
                                        }
                                        i {
                                            margin-right: 0;
                                        }
                                    }

                                }
                            }
                        }
                    }

                    .subMenu {
                        padding-top: 0;
                        //padding-bottom: calc(100vh - 440px);
                        display: none;

                        &.open {
                            display: block;
                            overflow-y: scroll;
                            height: 100vh;
                        }

                        .mobilePanel {
                            display: flex;
                            justify-content: space-between;
                        }

                        .parentLink {
                            a {
                                color: #FFF;
                                font-size: 20px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 114.6%;
                                text-decoration: none;
                                display: block;
                                padding: 30px 0 20px;

                                &:hover {
                                    color: #FFD43A;
                                }
                            }
                        }

                        .back {
                            cursor: pointer;
                            display: block;
                            padding: 30px 0 20px;
                            color: #FFF;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 114.6%; /* 22.92px */
                            i {
                                margin-right: 7px;
                            }
                        }

                        .container {
                            .w50 {
                                width: 100%;

                                & > .item {
                                    & > .link  {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;

                                        & > a {
                                            //margin-bottom: 14px;
                                            padding: 16px 0;
                                            font-size: 18px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 114.6%; /* 20.628px */

                                        }
                                        i {
                                            padding: 16px;
                                            transform: rotateZ(90deg);
                                            font-size: 20px;
                                        }
                                        &:hover {
                                            i {
                                                margin-right: 0;
                                                //transform: rotateZ(-90deg);
                                            }
                                        }
                                    }


                                    &.open {
                                        & > .link {
                                            & > a {
                                                color: #FFD43A;
                                            }
                                            i {
                                                color: #FFD43A;
                                                transform: rotateZ(-90deg);
                                            }
                                        }

                                        .subChildMenu {
                                            display: block!important;
                                        }
                                    }


                                    .subChildMenu {
                                        width: 100%;
                                        display: none!important;
                                        position: static;
                                        padding-left: 0;
                                        padding-top: 0;
                                        margin-top: 14px;

                                        .item {
                                            a {
                                                font-size: 14px;
                                                font-style: normal;
                                                font-weight: 300;
                                                line-height: 114.6%; /* 16.044px */
                                                margin-bottom: 20px;
                                                &:hover {
                                                    color: #FFD43A;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }
            .rightBlock {
                .contacts {
                    display: none;
                }

                .menuButton {
                    cursor: pointer;
                    display: block;
                    line-height: 0;
                    i {
                        font-size: 28px;
                        color: #ffffff;
                    }
                    .icon-menu {
                        display: block;
                    }
                    .icon-close {
                        display: none;
                    }
                    &.open {
                        .icon-menu {
                            display: none;
                        }
                        .icon-close {
                            display: block;
                        }
                    }
                }
                .languages {
                    margin-right: 14px;
                    .subLanguages {
                        padding-top: 9px;
                    }
                }

            }
            .logo {
                position: static;
                transform: none;
                max-width: 155px;
                max-height: 40px;
            }
        }
    }
}
