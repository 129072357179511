section.sectionNews {
    padding: 96px 0 50px;

    .container {
        .news {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 28px;
            grid-row-gap: 38px;

            .item {
                .image {
                    display: block;
                    border-radius: 4px;
                    overflow: hidden;
                    text-decoration: none;
                    margin-bottom: 16px;
                    line-height: 0;
                    height: 260px;
                    background-size: cover;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
                .date {
                    color: #828282;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 28px */
                    padding-bottom: 10px;
                    margin-bottom: 16px;
                    border-bottom: 1px solid #F2F2F2;
                }
                .title {
                    text-decoration: none;
                    display: block;
                    color: #333;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 114%; /* 31.92px */
                    margin-bottom: 10px;
                    @include maxLines(3, 31.5);
                }
                .description {
                    color: #828282;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: normal;
                    margin-bottom: 21px;
                    @include maxLines(2, 25.2);
                }
                .arrow {
                    display: block;
                    line-height: 0;
                }
            }
        }

        .newsPagination {
            margin-top: 63px;

            .pagination {
                display: flex;
                justify-content: center;
                align-items: center;

                li {
                    list-style: none;
                }

                .page-circle {
                    display: block;
                    height: 64px;
                    width: 64px;
                    position: relative;
                    border-radius: 64px;
                    border: 1px solid #E0E0E0;
                    margin: 0 10px;
                    flex-shrink: 0;

                    i {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateY(-50%) translateX(-50%);
                        color: #1F2732;
                        font-size: 12px;
                    }
                }

                .page-item {
                    width: 64px;
                    height: 64px;
                    position: relative;
                    margin: 0 10px;

                    &.active {
                        .page-link {
                            color: #FFD43A;
                        }
                    }

                    .page-link {
                        text-decoration: none;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateY(-50%) translateX(-50%);

                        color: #1F2732;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
                }

                /*. {
                    text-align: center;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }*/
            }
        }
    }
    @media screen and (max-width: 900px) {
        padding: 30px 0 61px;
        .container {
            .news {
                grid-template-columns: 1fr;
                grid-row-gap: 30px;
                .item {
                    .image {
                        height: 220px;
                        margin-bottom: 12px;
                    }
                    .date {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%; /* 19.2px */
                        padding-bottom: 11px;
                        margin-bottom: 12px;
                    }
                    .title {
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 114%; /* 22.8px */
                        margin-bottom: 10px;
                    }
                    .description {
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: normal;
                        margin-bottom: 21px;
                    }
                }
            }

            .newsPagination {
                .pagination {
                    .page-circle {
                        height: 50px;
                        width: 50px;

                        &:first-child {
                            margin-left: 0;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                        i {

                        }
                    }
                    .page-item {
                        width: 50px;
                        height: 50px;
                        .page-link {
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                        }
                    }
                }
            }
        }

    }
}
